
.kazds-full-page-container {
  background-color: $white;
  min-height: 100vh;

  &.center-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
