
@mixin h1-font {
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
  font-weight: bold;
}

@mixin h2-font {
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-weight: bold;
}

@mixin h3-font {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: bold;
}

@mixin h4-font {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: bold;
}

@mixin form-label {
  color: $primary-color;
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
}

@mixin btn-font {
  font-size: 14px;
  font-weight: bold;
}

.h1-text {
  @include h1-font;
}

.h2-text {
  @include h2-font;
}

.h3-text {
  @include h3-font;
}

.terms-and-privacy-font {
  color: $grey;

  a {
    font-size: 11px;
    color: $grey;
  }
}
