
.kazds-btn {
  border: none;
  margin: 0;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out,
              transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;

  background-color: $primary-color;
  color: $white;

  &.btn-primary {
    @include btn-font;

    font-size: 16px;
    background-color: $primary-color;
    color: $white;

    height: 32px;
    padding: 0 $spacing-5;

    &:hover,
    &:focus {
      background: #0053ba;
    }
  }

  &.btn-secondary {
    @include btn-font;

    font-size: 16px;
    background-color: $white;
    border: 1px solid $primary-color;
    color: $primary-color;

    height: 32px;
    padding: 0 $spacing-5;

    &:hover,
    &:focus {
      background-color: $primary-color;
      color: $white;
    }

    &.danger {
      color: $red;
      border-color: $red;

      &:hover,
      &:focus {
        background-color: $red;
        color: $white;
      }

      &:disabled {
        color: $black;
        border-color: $black;

        &:hover {
          background-color: $grey;
          cursor: default;
        }
      }
    }
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    background-color: $grey;

    &:hover {
      background-color: $grey;
      cursor: default;
    }
  }
}

.btn-link {
  color: $primary-color;
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
