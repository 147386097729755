
.kazds-form-field {
  &-label {
    @include form-label;

    margin-bottom: $spacing-2;
    display: flex;
    justify-content: space-between;

    .error {
      color: $red;
    }
  }
}

.kazds-text-input {
  display: block;
  width: calc(100% - 16px);
  border-radius: 4px;
  outline: none;
  border: none;
  background-color: #F0F0F0;
  padding: 8px;
  font-size: 16px;

  &.error {
    border: 1px solid $red;
  }
}
