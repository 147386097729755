
.m {
  &-b {
    &-1 {
      margin-bottom: $spacing-1;
    }
    &-2 {
      margin-bottom: $spacing-2;
    }
    &-3 {
      margin-bottom: $spacing-3;
    }
    &-4 {
      margin-bottom: $spacing-4;
    }
    &-5 {
      margin-bottom: $spacing-5;
    }
    &-6 {
      margin-bottom: $spacing-6;
    }
    &-7 {
      margin-bottom: $spacing-7;
    }
    &-8 {
      margin-bottom: $spacing-8;
    }
    &-9 {
      margin-bottom: $spacing-9;
    }
    &-10 {
      margin-bottom: $spacing-10;
    }
  }
  &-t {
    &-1 {
      margin-top: $spacing-1;
    }
    &-2 {
      margin-top: $spacing-2;
    }
    &-3 {
      margin-top: $spacing-3;
    }
    &-4 {
      margin-top: $spacing-4;
    }
    &-5 {
      margin-top: $spacing-5;
    }
    &-6 {
      margin-top: $spacing-6;
    }
    &-7 {
      margin-top: $spacing-7;
    }
    &-8 {
      margin-top: $spacing-8;
    }
    &-9 {
      margin-top: $spacing-9;
    }
    &-10 {
      margin-top: $spacing-10;
    }
  }
  &-l {
    &-1 {
      margin-left: $spacing-1;
    }
    &-2 {
      margin-left: $spacing-2;
    }
    &-3 {
      margin-left: $spacing-3;
    }
    &-4 {
      margin-left: $spacing-4;
    }
    &-5 {
      margin-left: $spacing-5;
    }
    &-6 {
      margin-left: $spacing-6;
    }
    &-7 {
      margin-left: $spacing-7;
    }
    &-8 {
      margin-left: $spacing-8;
    }
    &-9 {
      margin-left: $spacing-9;
    }
    &-10 {
      margin-left: $spacing-10;
    }
  }
  &-r {
    &-1 {
      margin-right: $spacing-1;
    }
    &-2 {
      margin-right: $spacing-2;
    }
    &-3 {
      margin-right: $spacing-3;
    }
    &-4 {
      margin-right: $spacing-4;
    }
    &-5 {
      margin-right: $spacing-5;
    }
    &-6 {
      margin-right: $spacing-6;
    }
    &-7 {
      margin-right: $spacing-7;
    }
    &-8 {
      margin-right: $spacing-8;
    }
    &-9 {
      margin-right: $spacing-9;
    }
    &-10 {
      margin-right: $spacing-10;
    }
  }
}
