/** @format */

html {
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  position: relative;
}

.center-text {
  text-align: center;
}

.normal-case {
  text-transform: initial;
}

.flex-box {
  display: flex;

  &.center-horizontal {
    justify-content: center;
  }

  &.center-vertical {
    align-items: center;
  }
}

.setup-flow-form {
  width: 80%;
  max-width: 270px;
}

.react-autosuggest__suggestions-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

@keyframes loading {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
