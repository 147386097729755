$left-nav-width: 300px;

.kazds-page-with-left-nav {
  background-color: $page-bg-color;
  height: 100vh;
  display: flex;
  justify-content: flex-start;

  &-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
  }

  &-body {
    background-color: $ghost-white;
    height: 100vh;
    width: calc(100% - #{$left-nav-width});
  }
}


.kazds-left-nav {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 100vh;
  width: $left-nav-width;
  z-index: 5;

  &-sphere-header {
    border-bottom: 1px solid $grey;

    &-notification {
      border-radius: 20px;
      background-color: $primary-color;
      position: absolute;
      right: 16px;
      font-size: 12px;
      padding: 0px 8px;
      color: $white;
      font-weight: bold;
    }

    &-drop-up {
      width: 100%;
      justify-content: flex-start !important;
      padding: $spacing-3 $spacing-6 !important;
    }

    &-container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    &-logo-img {
      margin-right: $spacing-4;
    }

    &-name {
      @include h3-font;
      text-transform: none;

      align-items: center;
      display: flex;
      justify-content: flex-start;

      &-text {
        text-align: left;
        width: 130px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .drop-down-icon {
        font-size: 18px;
        margin-left: $spacing-1;
      }
    }
  }

  &-logo {
    &-container {
      height: 80px;
      display: flex;
      align-items: center;
      padding: 0 20px;
      border-bottom: 1px solid $grey;
    }

    &-img {
      height: 50px;
    }
  }

  &-footer {
    height: 60px;
  }

  &-body {
    padding-top: $spacing-3;
    height: calc(100vh - 60px - 65px - 12px);
  }
}

.spheres-list {
  &-sphere {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    color: initial;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: $spacing-3 $spacing-5;
    position: relative;
    margin-bottom: $spacing-3;
    text-decoration: none;

    &:hover {
      cursor: pointer;
      background-color: $ghost-white;
    }

    &-img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 25px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

      &.add-icon {
        box-shadow: none;
        color: $grey;
        font-size: 40px;
      }

      &-container {
        width: 40px;
        height: 40px;

        margin-right: $spacing-4;
      }
    }

    &-name {
      font-weight: bold;
    }

    &.selected {
      outline: 2px solid $primary-color;
      outline-offset: -2px;
    }

    &-notification {
      align-items: center;
      display: flex;
      width: 20px;
      height: 20px;
      background-color: $primary-color;
      border-radius: 10px;
      position: absolute;
      right: 12px;
      font-size: 11px;
      justify-content: center;
      padding: 0 $spacing-2;
      text-transform: uppercase;
      font-weight: bold;
      color: $white;
    }
  }
}
