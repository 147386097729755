
// Colors

$kaz-blue: #348CF7;
$kaz-dark-blue: #140D4F;
$kaz-light-blue: #c9e0fd;

$ghost-white: #F8F8FF;
$black: #0D0106;
$white: #FFFFFF;
$grey: #CECCCC;
$grey-bg: #F0F0F0;
$red: #CC0000;
$red-light: #FFCCCC;


$page-bg-color: $ghost-white;
$primary-color: $kaz-blue;
$secondary-color: $kaz-dark-blue;

// Spacing

$spacing-1: 4px;
$spacing-2: 8px;
$spacing-3: 12px;
$spacing-4: 16px;
$spacing-5: 20px;
$spacing-6: 24px;
$spacing-7: 28px;
$spacing-8: 32px;
$spacing-9: 36px;
$spacing-10: 40px;
