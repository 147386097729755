/** @format */

.login-panel {
  max-width: 500px;
  width: 80%;
  margin: 50px 0;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  border: none !important;

  &-logo {
    width: 150px;
    height: 100%;

    &-container {
      display: flex;
      justify-content: center;
    }
  }

  &-header {
    &-logo {
      text-align: center;
      height: 50px;

      &-img {
        height: 100%;
        border-radius: 50px;
      }
    }
  }

  &-no-mobile {
    color: #bd1628;
    font-weight: bold;
    background-color: #bd162822;
    padding: 10px;
    border-radius: 4px;
  }
}

.chrome-extension-arrow {
  position: absolute;
  top: 0;
  right: 70px;
  height: 200px;

  -webkit-animation: breathing 1.5s ease-out infinite normal;
  animation: breathing 1.5s ease-out infinite normal;
  -webkit-font-smoothing: antialiased;
}

.logout-option {
  position: absolute;
  top: 20px;
  right: 24px;

  &-link {
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    display: inline;
    margin: 0;
    padding: 0;
  }

  &-link-button:hover,
  &-link-button:focus {
    text-decoration: none;
  }
}
