.kazds-form-field.checkbox {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  .kazds-checkbox-input {
    margin-right: $spacing-3;
  }

  .checkbox-text {
    font-size: 14px;
  }
}
