.kazds-text-filter-input {
  width: 150px;

  &-input {
    border: none;
    border-bottom: 1px solid black;
    margin-bottom: 8px;
    font-size: 16px;
    padding-bottom: 4px;
    width: 100%;

    &:focus, &:active {
      outline: none;
    }
  }

  &-label {
    @include form-label;

    font-size: 12px;
  }
}
