/** @format */

.App {
  display: flex;
  align-items: center;
  flex-direction: column;

  .join-form {
    text-align: center;
    display: flex;
    align-items: center;
    margin-top: 30px;
    max-width: 600px;

    &-email-input {
      border: none;
      border-radius: 8px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
      outline: none;
      font-size: 18px;
      padding: 16px;
      width: 65%;
      max-width: 80%;
      margin-right: 5%;

      &.error {
        border: 2px solid #bd1628;
      }
    }

    &-btn {
      border: none;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
      outline: none;
      font-size: 18px;
      padding: 16px 24px;
      border-radius: 8px;
      background-color: black;
      color: white;
      text-transform: uppercase;
      font-weight: bold;
      width: 35%;

      &:hover {
        cursor: pointer;
        background-color: #3d3d3d;
      }

      &:disabled {
        background-color: #a1a0a0;
        cursor: initial;
      }
    }
  }

  .signed-up {
    color: white;
    font-weight: bold;
    font-size: 19px;
    margin: 0 10%;
    margin-bottom: 20px;
  }

  .examples {
    display: flex;
    justify-content: center;
    position: relative;
    width: 90%;
    max-width: 1000px;
    margin-bottom: 50px;

    &-browser {
      width: 100%;
      max-width: 1000px;
      height: min-intrinsic;
      object-fit: contain;
    }
  }

  .testimonial {
    margin-bottom: 80px;
    display: flex;
    justify-content: center;
    position: relative;
    width: 80%;
    background-color: white;
    padding: 10px;
    padding-bottom: 20px;
    border-radius: 8px;

    img {
      width: 100%;
      max-width: 600px;
      height: 100%;
    }
  }

  .footer {
    margin: 30px 0;

    a {
      text-decoration: none;
      color: #a1a0a0;

      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.nav {
  width: 100%;
  max-width: 1110px;
  padding: 15px 24px;
  margin-top: 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #c1dcfd;

  &-logo {
    img {
      width: 150px;
    }
  }

  &-option-section {
    display: flex;
    align-items: center;
  }

  &-option {
    margin-left: 40px;

    a {
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      text-decoration: none;

      &:hover {
        cursor: pointer;
        color: #348cf7;
      }
    }
  }

  button {
    border-radius: 4px;
    text-transform: uppercase;
    background-color: black !important;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);

    &:hover {
      cursor: pointer;
      background-color: #3d3d3d !important;
    }
  }
}

@media (max-width: 500px) {
  .App {
    .nav {
      display: block;
      padding: 16px;

      &-logo {
        display: flex;
        justify-content: center;
      }
    }

    .header-grid {
      margin-top: 24px;
    }
  }
}

@media (max-width: 770px) {
  .App {
    .nav {
      margin-top: 0;
    }

    .nav-option-section {
      display: grid;
      grid-template-columns: 0.5fr 0.5fr;
      grid-column-gap: 15px;
      grid-row-gap: 15px;

      .nav-option {
        margin-left: 0;
        width: 110px;
        text-align: center;
      }
    }

    .join-form {
      display: block;
      width: 100%;
    }

    .footer-join-section {
      .join-form {
        display: block;
        width: 100%;
      }
    }

    .join-form-btn {
      width: 100%;
    }

    .join-form-email-input {
      width: 100%;
      margin: 0;
      margin-bottom: 20px;
      max-width: 100%;
      box-sizing: border-box;
    }

    .headline {
      font-size: 30px;
    }

    .sub-headline {
      display: initial;
      font-size: 20px;
    }

    .section-body-text {
      font-size: 16px;
    }

    .header {
      padding-bottom: 0;

      .header-container {
        .header-grid {
          grid-template-columns: 1fr;

          .signup-container {
            width: calc(100vw - 48px);
          }

          .demo {
            margin-top: 120px;
            transform: scale(1);

            &-container {
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
    }

    .sidebyside-header-text {
      margin-bottom: 0;
    }

    .sidebyside-grid {
      grid-template-columns: 1fr;
      padding-bottom: 40px;

      .sidebyside-code {
        transform: scale(0.8);

        &-container {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .sidebyside-explo {
        transform: scale(0.8);
        width: 480px;

        &-container {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .explanation-grid {
      grid-template-columns: 1fr;
    }

    .explanation-panel {
      padding: 0 40px;
      margin-bottom: 40px;
    }

    .bio-grid {
      grid-template-columns: 1fr;
    }

    .as-seen-on-container {
      margin-top: 100px;
    }

    .demo-video-container {
      iframe {
        height: 300px;
      }
    }
  }
}

.header {
  padding-bottom: 300px;
  width: 100%;

  &-container {
    max-width: 1100px;
    margin: 0 auto;
    padding: 0 24px;
    width: 100%;
    min-height: 400px;
    box-sizing: border-box;
  }

  &-grid {
    margin-top: 120px;
    grid-template-columns: 0.9fr 0.2fr;
    grid-template-rows: auto;
    display: grid;
    grid-gap: 0 30px;
    gap: 0 30px;
    text-align: left;
  }

  .header-text {
    color: #333333;
    font-size: 42px;
    font-weight: bold;
    line-height: 45px;
    margin-bottom: 20px;

    .primary-color-text {
      color: #348cf7;
    }
  }

  .subheader-text {
    font-size: 24px;
    color: #333333;
    font-weight: 100;
  }

  .demo {
    box-shadow: 1px 1px 5px 0 rgba(26, 26, 67, 0.05), 39px 62.5px 125px -25px rgba(50, 50, 93, 0.5),
      23.4px 37.5px 75px -37.5px rgba(0, 0, 0, 0.6);
    transform: scale(1.3) translateX(50px) translateY(0) perspective(3190px) rotateY(-29deg)
      rotateX(4deg) rotate(1deg);
    width: 400px;
    height: 350px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    align-items: center;
    overflow: hidden;

    &.step-1 {
      .main-content {
        .table-2 {
          width: 280px;
          height: 80px;
          top: 110px;
          left: 110px;
        }

        .add-flow-panel {
          top: 210px;

          .option {
            &.filter {
              background-color: #348cf7;
              color: white;
            }
          }
        }
      }
    }

    &.step-2 {
      .main-content {
        .table-2 {
          width: 280px;
          height: 80px;
          top: 110px;
          left: 110px;
        }

        .add-flow-panel {
          top: 210px;
        }
      }
    }

    &.step-3 {
      .main-content {
        .table-2 {
          width: 280px;
          height: 80px;
          top: 110px;
          left: 110px;
        }

        .table-3 {
          width: 280px;
          height: 80px;
          top: 210px;
          left: 110px;
        }

        .add-flow-panel {
          top: 310px;

          .option {
            &.pivot {
              background-color: #348cf7;
              color: white;
            }
          }
        }
      }
    }

    &.step-4 {
      .main-content {
        .table-2 {
          width: 280px;
          height: 80px;
          top: 110px;
          left: 110px;
        }

        .table-3 {
          width: 280px;
          height: 80px;
          top: 210px;
          left: 110px;
        }

        .add-flow-panel {
          top: 310px;
        }
      }
    }

    &.step-5 {
      .main-content {
        .table-1 {
          top: -90px;
        }
        .table-2 {
          width: 280px;
          height: 80px;
          top: 10px;
          left: 110px;
        }

        .table-3 {
          width: 280px;
          height: 80px;
          top: 110px;
          left: 110px;
        }

        .table-4 {
          width: 280px;
          height: 80px;
          top: 210px;
          left: 110px;
        }

        .add-flow-panel {
          top: 310px;

          .option {
            &.enrich {
              background-color: #348cf7;
              color: white;
            }
          }
        }
      }
    }

    &.step-6 {
      .main-content {
        .table-1 {
          top: -90px;
        }
        .table-2 {
          width: 280px;
          height: 80px;
          top: 10px;
          left: 110px;
        }

        .table-3 {
          width: 280px;
          height: 80px;
          top: 110px;
          left: 110px;
        }

        .table-4 {
          width: 280px;
          height: 80px;
          top: 210px;
          left: 110px;
        }

        .add-flow-panel {
          top: 310px;
        }
      }
    }

    &.step-7 {
      .main-content {
        .table-1 {
          top: -190px;
        }
        .table-2 {
          width: 280px;
          height: 80px;
          top: -90px;
          left: 110px;
        }

        .table-3 {
          width: 280px;
          height: 80px;
          top: 10px;
          left: 110px;
        }

        .table-4 {
          width: 280px;
          height: 80px;
          top: 110px;
          left: 110px;
        }

        .table-5 {
          width: 280px;
          height: 80px;
          top: 210px;
          left: 110px;
        }

        .add-flow-panel {
          top: 310px;

          .option {
            &.graphs {
              background-color: #348cf7;
              color: white;
            }
          }
        }
      }
    }

    .sidebar {
      width: 100px;
      height: 100%;
      background-color: #348cf7;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &-header {
        width: 100%;
        height: 30px;
        border-top-left-radius: 10px;
      }

      &-footer {
        background-color: #333333;
        width: 100%;
        height: 30px;
        border-bottom-left-radius: 10px;
      }
    }

    .main-content {
      background-color: #f5f8ff;
      box-sizing: border-box;
      width: 300px;
      height: 100%;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;

      .table {
        transition-property: top, left, width, height;
        transition-duration: 1.5s;
        overflow: hidden;
        box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
        background-color: white;

        .example-graph {
          width: 280px;
        }

        &-inside {
          display: grid;
          grid-template-columns: repeat(5, 55px);
          grid-template-rows: repeat(8, 8px);
          grid-column-gap: 2px;
          grid-row-gap: 2px;

          &.less-cols {
            grid-template-columns: repeat(3, 91px);
          }

          .table-header {
            grid-row-start: 1;
            grid-column: 1 / span 5;
            background-color: #333333;
          }

          .table-cell {
            background-color: #f2f2f2;

            &.dark-grey {
              background-color: #c5c5c5;
            }

            &.light-blue {
              background-color: #65a8f9;
            }
            &.dark-blue {
              background-color: #2462ad;
            }
          }
        }
      }

      .table-1 {
        position: absolute;
        width: 280px;
        height: 80px;
        top: 10px;
        left: 110px;
      }

      .table-2 {
        position: absolute;
        width: 0;
        height: 0;
        top: 100px;
        left: 250px;
      }

      .table-3 {
        position: absolute;
        width: 0;
        height: 0;
        top: 200px;
        left: 250px;
      }

      .table-4 {
        position: absolute;
        width: 0;
        height: 0;
        top: 280px;
        left: 250px;
      }

      .table-5 {
        position: absolute;
        width: 0;
        height: 0;
        top: 280px;
        left: 250px;
      }

      .add-flow-panel {
        box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
        background-color: white;
        height: 25px;
        position: absolute;
        width: 280px;
        top: 110px;
        left: 110px;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        transition-property: top;
        transition-duration: 1.5s;

        .add-icon-container {
          background-color: #f2f2f2;
          border-radius: 50px;
          height: 12px;
          width: 12px;
          display: flex;
          margin: 0 5px;

          .add-icon {
            font-size: 12px;
            color: #333333;
          }
        }

        .option {
          border-radius: 4px;
          font-size: 10px;
          text-transform: uppercase;
          padding: 0 15px;
          transition-property: background-color, color;
          transition-duration: 0.25s;
        }
      }
    }
  }
}

.background-grid {
  --content-columns: 12;
  --gutter-columns: 4;
  position: absolute;
  width: 100%;
  top: 150px;
  bottom: 0;
  z-index: -1;
  pointer-events: none;
}

.background-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: flex-end;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 800px;
  -webkit-transform: skewY(-12deg);
  transform: skewY(-12deg);

  .grid {
    --content-column-width: 100px;
    --gutter-column-width: 100px;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(auto-fill, 70px);
    min-width: 0;
    height: 200%;
    align-content: end;
    width: 100%;
    display: grid;
    position: absolute;

    .background {
      background-color: #f5f8ff;
      grid-row: 1/-6;
      grid-column: 1/-1;
      z-index: -1;
    }
  }
}

.boxes-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: flex-end;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 800px;
  -webkit-transform: skewY(-12deg);
  transform: skewY(-12deg);

  .grid {
    --content-column-width: 70px;
    --gutter-column-width: 70px;
    grid-template-columns:
      [viewport-start] 1fr [left-gutter-start] repeat(4, 70px)
      [left-gutter-end content-start] repeat(12, 70px)
      [content-end right-gutter-start] repeat(4, 70px) [right-gutter-end] 1fr [viewport-end];
    grid-template-rows: repeat(auto-fill, 70px);
    min-width: 0;
    height: 200%;
    align-content: end;
    width: 100%;
    display: grid;
    position: absolute;

    .color-panel {
      &.blue {
        background-color: #348cf7;
      }
      &.lightest-blue {
        background-color: #d8e9fd;
      }
      &.light-blue {
        background-color: #65a8f9;
      }
      &.dark-blue {
        background-color: #2462ad;
      }
      &.grey {
        border: 2px solid #f2f2f2;
        &.full {
          background-color: #f2f2f2;
        }
      }
      &.dark-grey {
        border: 2px solid #333333;
        &.full {
          background-color: #333333;
        }
      }

      &.p1 {
        grid-row-start: -5;
        grid-column: 15 / span 7;
      }
      &.p2 {
        grid-row-start: -6;
        grid-column: 14 / span 3;
      }
      &.p3 {
        grid-row-start: -7;
        grid-column: 15 / span 3;
      }
      &.p4 {
        grid-row-start: -7;
        grid-column: 3 / span 3;
      }
      &.p5 {
        grid-row-start: -6;
        grid-column: 1 / span 3;
      }
      &.p7 {
        grid-row-start: -4;
        grid-column: 12 / span 3;
      }
      &.p8 {
        grid-row-start: -10;
        grid-column: 19 / span 3;
      }
      &.p9 {
        grid-row-start: -11;
        grid-column: 20 / span 3;
      }
      &.p10 {
        grid-row-start: -5;
        grid-column: 1 / span 2;
      }
      &.p11 {
        grid-row-start: -13;
        grid-column: 1 / span 2;
      }
    }
  }
}

.section {
  width: 100%;
  max-width: 1100px;
  padding: 50px 24px;
  box-sizing: border-box;

  &.full-width {
    max-width: none;
    padding: 0;
  }

  &.border-bottom {
    border-bottom: 2px solid #efefef;
  }

  &-header-text {
    font-size: 42px;
    text-align: center;
    margin-bottom: 40px;
  }

  &-body-text {
    font-size: 26px;
    font-weight: 100;
    text-align: center;
  }
}

.explanation {
  &-grid {
    display: grid;
    grid-template-columns: 0.33fr 0.33fr 0.33fr;
    grid-template-rows: auto;
    grid-column-gap: 24px;
  }

  &-panel {
    color: #333333;

    &-title {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 10px;
    }

    &-sub-title {
      text-transform: uppercase;
      font-weight: bold;
      color: #348cf7;
      margin-bottom: 5px;
    }

    &-body {
      font-size: 18px;
      line-height: 28px;
    }

    &-icon {
      img {
        height: 50px;
        margin-bottom: 15px;
      }
    }
  }
}

.company-logos {
  height: 150px;
  border-bottom: 2px solid #efefef;
  border-top: 2px solid #efefef;
}

.explo-flow-img {
  width: 100%;
}

.sidebyside {
  &-header-text {
    margin-bottom: 100px;
  }
  &-grid {
    display: grid;
    grid-template-columns: 0.5fr 0.5fr;
    grid-template-rows: auto;
    grid-column-gap: 24px;
    padding-bottom: 150px;

    &.sidebyside-step-1 {
      .explo-step {
        &-1 {
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.75);
          opacity: 1;
          .explo-step-body {
            background-color: #d8e9fd;
          }
        }
      }

      .editor-area-container {
        .code-segment.step-1 {
          opacity: 1;
        }
      }
    }

    &.sidebyside-step-2 {
      .explo-step {
        &-1 {
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.75);
          opacity: 1;

          .explo-step-body {
            background-color: #d8e9fd;
          }
        }
        &-2 {
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.75);
          opacity: 1;

          .explo-step-body {
            background-color: #d8e9fd;
          }
        }
      }

      .editor-area-container {
        .code-segment.step-1,
        .code-segment.step-2 {
          opacity: 1;
        }
      }
    }

    &.sidebyside-step-3 {
      .explo-step {
        &-1 {
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.75);
          opacity: 1;

          .explo-step-body {
            background-color: #d8e9fd;
          }
        }
        &-2 {
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.75);
          opacity: 1;

          .explo-step-body {
            background-color: #d8e9fd;
          }
        }
        &-3 {
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.75);
          opacity: 1;

          .explo-step-body {
            background-color: #d8e9fd;
          }
        }
      }

      .editor-area-container {
        .code-segment.step-1,
        .code-segment.step-2,
        .code-segment.step-3 {
          opacity: 1;
        }
      }
    }

    &.sidebyside-step-4 {
      .explo-step {
        &-1 {
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.75);
          opacity: 1;

          .explo-step-body {
            background-color: #d8e9fd;
          }
        }
        &-2 {
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.75);
          opacity: 1;

          .explo-step-body {
            background-color: #d8e9fd;
          }
        }
        &-3 {
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.75);
          opacity: 1;

          .explo-step-body {
            background-color: #d8e9fd;
          }
        }
        &-4 {
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.75);
          opacity: 1;

          .explo-step-body {
            background-color: #d8e9fd;
          }
        }
      }

      .editor-area-container {
        .code-segment.step-1,
        .code-segment.step-2,
        .code-segment.step-3,
        .code-segment.step-4 {
          opacity: 1;
        }
      }
    }

    &.sidebyside-step-5 {
      .explo-step {
        &-1 {
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.75);
          opacity: 1;

          .explo-step-body {
            background-color: #d8e9fd;
          }
        }
        &-2 {
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.75);
          opacity: 1;

          .explo-step-body {
            background-color: #d8e9fd;
          }
        }
        &-3 {
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.75);
          opacity: 1;

          .explo-step-body {
            background-color: #d8e9fd;
          }
        }
        &-4 {
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.75);
          opacity: 1;

          .explo-step-body {
            background-color: #d8e9fd;
          }
        }
      }

      .editor-area-container {
        .code-segment.step-1,
        .code-segment.step-2,
        .code-segment.step-3,
        .code-segment.step-4 {
          opacity: 1;
        }
      }
    }
  }

  &-code {
    overflow: hidden;
    background-color: white;
    border-radius: 10px;
    box-shadow: 1px 1px 5px 0 rgba(26, 26, 67, 0.05), -39px 62.5px 125px -25px rgba(50, 50, 93, 0.5),
      23.4px 37.5px 75px -37.5px rgba(0, 0, 0, 0.6);
    height: 330px;
    width: 480px;
    transform: scale(1.3) translateX(0) translateY(0) perspective(3190px) rotateY(42deg)
      rotateX(1deg) rotate(-1deg);
    display: flex;
    font-family: 'Source Code Pro', monospace;

    .sidebar-lines {
      &-container {
        width: 50px;
        background-color: #f3f3f3;
        padding: 10px 0;
      }

      &-linenumber {
        padding: 0 15px;
        font-size: 13px;
      }
    }

    .editor-area-container {
      background-color: white;
      padding: 10px 15px;
      font-size: 13px;

      .editor-area-line {
        white-space: nowrap;
        overflow: hidden;

        .code-segment {
          opacity: 0.2;
          transition-property: opacity;
          transition-duration: 1s;
        }

        .code-brown {
          color: #9f4511;
        }
        .code-green {
          color: #0a6e2b;
        }
        .code-blue {
          color: #3288f0;
        }
        .code-red {
          color: #e41d24;
        }

        &.indent-1 {
          padding-left: 16px;
        }
        &.indent-2 {
          padding-left: 32px;
        }
      }
    }
  }

  &-explo {
    overflow: hidden;
    background-color: white;
    border-radius: 10px;
    box-shadow: 1px 1px 5px 0 rgba(26, 26, 67, 0.05), 39px 62.5px 125px -25px rgba(50, 50, 93, 0.5),
      23.4px 37.5px 75px -37.5px rgba(0, 0, 0, 0.6);
    height: 330px;
    width: 470px;
    transform: scale(1.3) translateX(20px) translateY(0) perspective(3190px) rotateY(-39deg)
      rotateX(4deg) rotate(1deg);

    .explo-step {
      background-color: white;
      margin: 17px;
      box-sizing: border-box;
      border-radius: 4px;
      overflow: hidden;
      transition-property: box-shadow, opacity;
      transition-duration: 1s;
      opacity: 0.5;

      &-title {
        font-size: 16px;
        font-weight: bold;
        padding: 5px 10px;
        background-color: #333333;
        color: white;
      }

      &-body {
        background-color: #dbdbdb;
        font-size: 14px;
        padding-left: 20px;
        padding: 10px;
        padding-top: 0;
        transition-property: background-color;
        transition-duration: 1s;
      }
    }
  }
}

.bio-grid {
  display: grid;
  grid-template-columns: 0.5fr 0.5fr;
  grid-column-gap: 100px;
  grid-row-gap: 100px;

  .bio-panel {
    padding: 30px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 1px 1px 5px 0 rgba(26, 26, 67, 0.05), 39px 62.5px 125px -25px rgba(50, 50, 93, 0.5),
      23.4px 37.5px 75px -37.5px rgba(0, 0, 0, 0.6);
    text-align: center;

    &-img {
      img {
        border-radius: 100px;
        width: 200px;
      }
    }

    &-name {
      font-weight: bold;
      font-size: 24px;
      margin: 16px 0;
    }

    &-desc {
      font-weight: 100;
      padding: 0 20px;
    }
  }
}

.footer-join-section {
  display: flex;
  justify-content: center;

  .join-form {
    width: 60%;
  }
}

.demo-video-container {
  margin-bottom: 40px;
}

.as-seen-on-container {
  .seen-on-items {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@keyframes captionFloatIn {
  from {
    font-size: 24px;
    opacity: 0;
  }

  to {
    font-size: 18px;
    opacity: 1;
  }
}

@keyframes headerFloatIn {
  from {
    opacity: 0;
    margin-top: 0px;
    margin-bottom: 32px;
  }

  to {
    opacity: 1;
    margin-top: 12px;
    margin-bottom: 20px;
  }
}

@keyframes descriptionFloatIn {
  from {
    opacity: 0;
    margin-top: -12px;
    margin-bottom: 108px;
  }

  to {
    opacity: 1;
    margin-top: 0px;
    margin-bottom: 96px;
  }
}

@keyframes signupFlowIn {
  from {
    opacity: 0;
    margin-top: -12px;
    margin-bottom: 12px;
  }

  to {
    opacity: 1;
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

// n is number of stars required
@function multipleBoxShadow($n) {
  $value: '#{random(2000)}px #{random(2000)}px #2F3540';
  @for $i from 2 through $n {
    $value: '#{$value} , #{random(2000)}px #{random(4000)}px #2F3540';
  }
  @return unquote($value);
}

$shadows-small: multipleBoxShadow(600);
$shadows-medium: multipleBoxShadow(100);
$shadows-large: multipleBoxShadow(20);

#stars2 {
  width: 4px;
  height: 4px;
  border-radius: 4px;
  background: transparent;
  box-shadow: $shadows-small;
}

#stars {
  width: 8px;
  height: 8px;
  border-radius: 10px;
  background: transparent;
  box-shadow: $shadows-medium;
}

#stars3 {
  width: 12px;
  height: 12px;
  border-radius: 15px;
  background: transparent;
  box-shadow: $shadows-large;
}
