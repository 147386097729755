/** @format */

@import 'styles/variables';
@import 'styles/text-styles';
@import 'styles/spacing-styles';
@import 'styles/shared';
@import 'styles/components';
@import 'styles/pages';

.react-grid-item.react-grid-placeholder {
  background-color: #adadad !important;
}

#embedded-container {
  height: 100%;
}
