
.kazds-panel {
  background-color: $white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: $spacing-5;

  &.no-padding {
    padding: 0;
  }
}
